/* ======================================
loaderの読み込み
====================================== */
import "./loader.js";

/* ======================================
itemGridSliderの読み込み
====================================== */
import "./itemGridSlider.js";

/* ======================================
smoothScrollの読み込み
====================================== */
import "./smoothScroll.js";

/* ======================================
モーダルの読み込み
====================================== */
import "./modalDialog.js";

// ===============================================
// fuature productのslider
// ===============================================
const swiper = new Swiper(".un_top_featureSlider", {
  centeredSlides: true,
  autoplay: {
    delay: 5000,
  },
  slidesPerView: 1.4,
  spaceBetween: 10,
  speed: 500,
  loop: true,
  breakpoints: {
    // when window width is >= 320px
    576: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    // when window width is >= 480px
    768: {
      slidesPerView: 2.6,
      spaceBetween: 10,
    },
    // when window width is >= 640px
    992: {
      slidesPerView: 3.4,
      spaceBetween: 30,
    },
    1200: {
      slidesPerView: 4,
      spaceBetween: 30,
    },
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
});

// ===============================================
// copyrightの年を自動で更新
// ===============================================
document.addEventListener("DOMContentLoaded", function () {
  const startYear = 2024; // 開始年を指定
  const currentYear = new Date().getFullYear();
  const yearElement = document.getElementById("copyrightYear");

  if (startYear === currentYear) {
    yearElement.textContent = startYear; // 開始年と現在年が同じ場合、開始年だけ表示
  } else {
    yearElement.textContent = `${startYear}–${currentYear}`; // 異なる場合は範囲表示
  }
});

// ===============================================
// ドロワーメニューの開閉
// ===============================================
const drawerMenu = document.querySelector(".bl_drawerMenu");
const drawerBtn = document.querySelector(".bl_drawerMenu_btn");
const drawerBody = document.querySelector(".bl_drawerMenu_body");

drawerBtn.addEventListener("click", () => {
  const isOpen = drawerBtn.classList.toggle("js_isOpen");

  drawerMenu.classList.toggle("js_isOpen", isOpen);
  // ドロワーメニューが開いている時はbodyのスクロールを禁止
  document.body.classList.toggle("js_noScroll", isOpen);

  drawerBtn.setAttribute("aria-expanded", isOpen);
});

/* ======================================
toTopの読み込み
====================================== */
import ToTop from "./toTop.js";
const toTop = new ToTop(".el_toTop");

// ===============================================
// アコーディオンメニューの開閉
// ===============================================
document.addEventListener("DOMContentLoaded", function () {
  const dtElements = document.querySelectorAll(".js_accordionMenu dt");

  dtElements.forEach((dt) => {
    dt.addEventListener("click", function () {
      this.classList.toggle("js_isOpen");

      let ddElement = this.nextElementSibling;
      // 連続する dd 要素を全てトグル
      while (ddElement && ddElement.tagName === "DD") {
        ddElement.classList.toggle("js_isOpen");
        ddElement = ddElement.nextElementSibling;
      }
    });
  });
});
