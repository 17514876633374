document.addEventListener("DOMContentLoaded", function () {
  /* ======================================
  hero itemGridのslider
  ====================================== */

  const gridContainer = document.querySelector(".un_top_hero_productGrid");

  if (gridContainer) {
    const itemGridRows = 6;
    const itemGridCols = 12;
    const itemCount = 24;

    /* -------------------------------
    itemGridのhtmlを動的に生成
    ------------------------------- */
    for (let i = 0; i < itemGridRows; i++) {
      const swiperDiv = document.createElement("div");
      swiperDiv.classList.add("swiper", `swiper${i + 1}`);

      const ul = document.createElement("ul");
      ul.classList.add("swiper-wrapper");

      for (let j = 0; j < itemGridCols + 2; j++) {
        const li = document.createElement("li");
        li.classList.add("swiper-slide");
        const itemIndex = (i * (itemGridCols + 2) + j) % itemCount;
        const img = document.createElement("img");
        img.src = `/wp/wp-content/themes/living-ichihara/assets/img/top_gridItem${String(itemIndex + 1).padStart(2, "0")}.webp`;
        // img.src = `./assets/img/top_gridItem${String(itemIndex + 1).padStart(2, "0")}.webp`;
        img.onload = function () {
          img.width = this.naturalWidth;
          img.height = this.naturalHeight;
        };
        li.appendChild(img);
        ul.appendChild(li);
      }

      swiperDiv.appendChild(ul);
      gridContainer.appendChild(swiperDiv);
    }

    /* -------------------------------
    Swiperを初期化
    ------------------------------- */
    const swipers = Array.from({ length: itemGridRows }, (_, i) => {
      return new Swiper(`.swiper${i + 1}`, {
        speed: 900,
        loop: true,
        slidesPerView: 4,
        allowTouchMove: false, // マウスでのドラッグを無効にする
        breakpoints: {
          576: {
            slidesPerView: 5,
          },
          768: {
            slidesPerView: 6,
          },
          992: {
            slidesPerView: 7,
          },
          1200: {
            slidesPerView: 9,
          },
          1400: {
            slidesPerView: 11,
          },
          2000: {
            slidesPerView: 12,
          },
        },
      });
    });

    let timer;
    const interval = 3000;

    const startSwipers = () => {
      timer = setInterval(() => {
        swipers.forEach((swiper, index) => {
          if (index % 2 === 0) {
            swiper.slideNext();
          } else {
            swiper.slidePrev();
          }
        });
      }, interval);
    };

    // Start the timer initially
    startSwipers();
  }
});
