export default class ToTop {
  #toTopElm;
  #hidePointY;
  #showPointY;
  #previousY = 0;
  #previous2Y = 0;

  constructor(className) {
    this.#toTopElm = document.querySelector(className);
    this.#hidePointY = document.documentElement.clientHeight / 2;
    this.#showPointY = document.documentElement.scrollHeight - document.documentElement.clientHeight * 1.5;
    this.setEventListener();
  }

  setEventListener() {
    window.addEventListener("scroll", () => {
      this.scrollFunction();
    });
  }

  scrollFunction() {
    const currentY = window.scrollY;

    let isScrollDown = this.isScrollDown();
    let isExcludeArea = this.isExcludeArea();
    let isIncludeArea = this.isIncludeArea();

    if (isIncludeArea) {
      this.#toTopElm.classList.add("js_show");
    } else if (isScrollDown || isExcludeArea) {
      this.#toTopElm.classList.remove("js_show");
    } else {
      this.#toTopElm.classList.add("js_show");
    }

    this.#previous2Y = this.#previousY;
    this.#previousY = currentY;
  }

  // scrollDownしたか判定する
  isScrollDown() {
    const currentY = window.scrollY;
    // ２回連続で下がっていたらtrue
    let result = !(this.#previous2Y > this.#previousY && this.#previousY > currentY);
    return result;
  }

  // 除外エリアの判定
  isExcludeArea() {
    const currentY = window.scrollY;
    let isExcludeArea = this.#hidePointY > currentY;
    return isExcludeArea;
  }

  // 包含エリア判定
  isIncludeArea() {
    const currentY = window.scrollY;
    let isIncludeArea = currentY > this.#showPointY;
    return isIncludeArea;
  }
}

// 使用例
// const toTop = new ToTop(".el_toTop");
