/* -------------------------------
モーダルダイアログ
------------------------------- */
const dialog = document.getElementById("modalDialog");
const openButton = document.getElementById("dialogOpenBtn");
const closeButton = document.getElementById("dialogCloseBtn");

// モーダルを開く
openButton?.addEventListener("click", async () => {
  dialog.showModal();
  // モーダルダイアログを表示する際に背景部分がスクロールしないようにする
  document.documentElement.style.overflow = "hidden";
  // ダイアログを表示した後にスクロールを先頭にリセット
  dialog.scrollTop = 0;
});

// モーダルを閉じる
closeButton?.addEventListener("click", () => {
  modalDialog.close();
  // モーダルを解除すると、スクロール可能になる
  document.documentElement.removeAttribute("style");
});
